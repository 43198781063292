.heading {
  font-size: 18px;
}

.para {
  font-weight: 400;
}

.feedback-level {
  display: flex;
  justify-content: center;
  align-items: center;
}
.level {
  background: white;
  padding: 8px;
  border-radius: 7px;
  cursor: pointer;
}
.level:hover i {
  color: #2B8A8C;
}
.level i {
  font-size: 40px;
  font-weight: 400;
}

.feedback-msg textarea {
  margin: 8px 0;
  border: solid 2px #e0e9fe;
  padding: 8px;
  border-radius: 7px;
  outline: none;
  font-size: 14px;
  color: #4b537c;
  font-weight: 500;
  letter-spacing: 1px;
  width: 100%;
  height: 80px;
  resize: none;
}

.agreement {
    margin: 8px 0;
}

.agreement .checkbox {
  display: flex;
  gap: 5px;
  align-items: center;
  margin-bottom: 5px;
}
.agreement input {
  accent-color: #11304F;
}
.agreement a {
  color: #11304F;
  font-size: 12px;
  font-weight: 600;
}
.agreement label {
  font-size: 12px;
}

.buttons {
  display: flex;
  gap: 10px;
  justify-content: end;
}
.buttons a {
  background: white;
  color: #4b537c;
  width: 80px;
  padding: 10px;
  text-align: center;
  border-radius: 4px;
  font-weight: bold;
}
.buttons a:hover {
  color: #2B8A8C;
}

.buttons a:nth-child(2):hover {
    background: #2B8A8C;
    color: white;
  }

.buttons a:nth-child(2) {
  background: #11304F;
  color: #f8faff;
}
