html {
    font-size: 1rem;
    line-height: 1.65;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
    -webkit-text-size-adjust: 100%;
    image-rendering: crisp-edges;
    scroll-behavior: smooth
}

main {
    display: block
}

h1 {
    font-size: 2em
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible
}

a {
    background-color: rgba(0, 0, 0, 0)
}

abbr[title] {
    border-bottom: none;
    text-decoration: underline;
    text-decoration: underline dotted
}

b,
strong {
    font-weight: bolder
}

small {
    font-size: 80%
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline
}

sub {
    bottom: -0.25em
}

sup {
    top: -0.5em
}

img {
    border-style: none
}

button,
input,
optgroup,
select,
textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0
}

button,
input {
    overflow: visible
}

button,
select {
    text-transform: none
}

button,
[type=button],
[type=reset],
[type=submit] {
    -webkit-appearance: button
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
    border-style: none;
    padding: 0
}

button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
    outline: 1px dotted ButtonText
}

fieldset {
    padding: .35em .75em .625em
}

legend {
    box-sizing: border-box;
    color: inherit;
    display: table;
    max-width: 100%;
    padding: 0;
    white-space: normal
}

progress {
    vertical-align: baseline
}

textarea {
    overflow: auto
}

[type=checkbox],
[type=radio] {
    box-sizing: border-box;
    padding: 0
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
    height: auto
}

[type=search] {
    -webkit-appearance: textfield;
    outline-offset: -2px
}

[type=search]::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit
}

details {
    display: block
}

summary {
    display: list-item
}

template {
    display: none
}

[hidden] {
    display: none
}

*,
*::before,
*::after {
    -webkit-box-sizing: inherit;
    box-sizing: inherit
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: 1.5rem;
    line-height: 1.25;
    font-weight: 600;
    color: #1d2d35
}

h1 {
    font-size: 2rem
}

h2 {
    font-size: 1.75rem
}

h3 {
    font-size: 1.5rem
}

h4 {
    font-size: 1.25rem
}

h5 {
    font-size: 1.1rem
}

h6 {
    font-size: 1rem
}

@media(min-width: 768px) {
    h1 {
        font-size: 3rem
    }

    h1.jumbo {
        font-size: 3.5rem
    }

    h2 {
        font-size: 2.5rem;
        margin-top: .75rem
    }

    h3 {
        font-size: 2rem
    }

    h4 {
        font-size: 1.5rem
    }

    h5 {
        font-size: 1.25rem
    }

    h6 {
        font-size: 1rem
    }
}

.kicker {
    text-transform: uppercase;
    color: rgba(43, 138, 140, 1);
    font-weight: 400;
    font-size: .85rem !important;
    letter-spacing: 1px;
    margin-bottom: .75rem;
    font-family: 'Roboto'
}

header {
    padding-bottom: 12rem
}

header #navbar .logo {
    max-width: 100px
}

#hero {
    padding: 32px 0 32px 0;
    background-image: radial-gradient(54.35% 54.35% at 13.82% 7%, #162051 0%, #161B32 100%)
}

#hero h1 {
    color: #fff;
    animation: fadeInUp 600ms .2s;
    animation-fill-mode: both
}

#hero p {
    font-size: 1rem;
    animation: fadeInUp 600ms .2s;
    animation-fill-mode: both;
    line-height: 1.5;
}

#hero .btn {
    animation: fadeInUp 600ms .2s;
    animation-fill-mode: both
}

#hero .btn.btn-secondary {
    color: #fff;
    border-color: #fff
}

#hero .btn.btn-secondary:hover {
    background-color: #fff;
    border-color: #fff;
    color: #1d2d35
}

@media all and (max-width: 768px) {
    header {
        padding-bottom: 6rem
    }

    #hero {
        padding: 0 0 6rem 0
    }

    #hero .hero-img {
        max-width: 65%;
        margin-top: 1.5rem
    }
}

#intro {
    padding: 2.5rem 0
}

#intro p {
    line-height: 1.5;
}

#intro .img-right {
    width: 100%;
    display: block;
    max-width: 500px;
    margin-left: auto;
    border-radius: 8px;
}

@media all and (max-width: 768px) {
    #intro {
        padding: 6rem 0
    }
}

#cta {
    background: #f9fafb;
    padding: 3rem 0
}

footer {
    padding: 6rem 0;
    background: radial-gradient(54.35% 54.35% at 13.82% 7%, #162051 0%, #161B32 100%)
}

footer h3 {
    font-size: .85rem;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 2px
}

footer ul {
    list-style: none;
    padding-left: 0
}

footer ul li {
    margin-bottom: 1.5rem
}

footer ul li a {
    color: #8391a7;
    text-decoration: none;
    font-size: .85rem;
    transition: all .3s ease-in-out
}

footer ul li a:hover {
    color: rgba(43, 138, 140, 1)
}

footer ul.social li {
    display: inline;
    margin-right: .75rem
}

footer ul.social li a {
    color: #fff
}

footer ul.social li a svg {
    max-width: 25px
}

footer ul.social li a:hover {
    color: rgba(43, 138, 140, 1)
}

footer .logo {
    max-width: 100px;
    margin-bottom: .75rem
}

.container {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px
}

.row {
    margin-left: -15px;
    margin-right: -15px
}

.row:before,
.row:after {
    content: " ";
    display: table
}

.row:after {
    clear: both
}

.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12 {
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px
}

.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11 {
    float: left
}

.col-xs-1 {
    width: 8.3333333333%
}

.col-xs-2 {
    width: 16.6666666667%
}

.col-xs-3 {
    width: 25%
}

.col-xs-4 {
    width: 33.3333333333%
}

.col-xs-5 {
    width: 41.6666666667%
}

.col-xs-6 {
    width: 50%
}

.col-xs-7 {
    width: 58.3333333333%
}

.col-xs-8 {
    width: 66.6666666667%
}

.col-xs-9 {
    width: 75%
}

.col-xs-10 {
    width: 83.3333333333%
}

.col-xs-11 {
    width: 91.6666666667%
}

.col-xs-12 {
    width: 100%
}

@media(min-width: 768px) {
    .container {
        max-width: 750px
    }

    .col-sm-1,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9,
    .col-sm-10,
    .col-sm-11 {
        float: left
    }

    .col-sm-1 {
        width: 8.3333333333%
    }

    .col-sm-2 {
        width: 16.6666666667%
    }

    .col-sm-3 {
        width: 25%
    }

    .col-sm-4 {
        width: 33.3333333333%
    }

    .col-sm-5 {
        width: 41.6666666667%
    }

    .col-sm-6 {
        width: 50%
    }

    .col-sm-7 {
        width: 58.3333333333%
    }

    .col-sm-8 {
        width: 66.6666666667%
    }

    .col-sm-9 {
        width: 75%
    }

    .col-sm-10 {
        width: 83.3333333333%
    }

    .col-sm-11 {
        width: 91.6666666667%
    }

    .col-sm-12 {
        width: 100%
    }

    .col-sm-push-1 {
        left: 8.3333333333%
    }

    .col-sm-push-2 {
        left: 16.6666666667%
    }

    .col-sm-push-3 {
        left: 25%
    }

    .col-sm-push-4 {
        left: 33.3333333333%
    }

    .col-sm-push-5 {
        left: 41.6666666667%
    }

    .col-sm-push-6 {
        left: 50%
    }

    .col-sm-push-7 {
        left: 58.3333333333%
    }

    .col-sm-push-8 {
        left: 66.6666666667%
    }

    .col-sm-push-9 {
        left: 75%
    }

    .col-sm-push-10 {
        left: 83.3333333333%
    }

    .col-sm-push-11 {
        left: 91.6666666667%
    }

    .col-sm-pull-1 {
        right: 8.3333333333%
    }

    .col-sm-pull-2 {
        right: 16.6666666667%
    }

    .col-sm-pull-3 {
        right: 25%
    }

    .col-sm-pull-4 {
        right: 33.3333333333%
    }

    .col-sm-pull-5 {
        right: 41.6666666667%
    }

    .col-sm-pull-6 {
        right: 50%
    }

    .col-sm-pull-7 {
        right: 58.3333333333%
    }

    .col-sm-pull-8 {
        right: 66.6666666667%
    }

    .col-sm-pull-9 {
        right: 75%
    }

    .col-sm-pull-10 {
        right: 83.3333333333%
    }

    .col-sm-pull-11 {
        right: 91.6666666667%
    }

    .col-sm-offset-1 {
        margin-left: 8.3333333333%
    }

    .col-sm-offset-2 {
        margin-left: 16.6666666667%
    }

    .col-sm-offset-3 {
        margin-left: 25%
    }

    .col-sm-offset-4 {
        margin-left: 33.3333333333%
    }

    .col-sm-offset-5 {
        margin-left: 41.6666666667%
    }

    .col-sm-offset-6 {
        margin-left: 50%
    }

    .col-sm-offset-7 {
        margin-left: 58.3333333333%
    }

    .col-sm-offset-8 {
        margin-left: 66.6666666667%
    }

    .col-sm-offset-9 {
        margin-left: 75%
    }

    .col-sm-offset-10 {
        margin-left: 83.3333333333%
    }

    .col-sm-offset-11 {
        margin-left: 91.6666666667%
    }
}

@media(min-width: 992px) {
    .container {
        max-width: 970px
    }

    .col-md-1,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-md-10,
    .col-md-11 {
        float: left
    }

    .col-md-1 {
        width: 8.3333333333%
    }

    .col-md-2 {
        width: 16.6666666667%
    }

    .col-md-3 {
        width: 25%
    }

    .col-md-4 {
        width: 33.3333333333%
    }

    .col-md-5 {
        width: 41.6666666667%
    }

    .col-md-6 {
        width: 50%
    }

    .col-md-7 {
        width: 58.3333333333%
    }

    .col-md-8 {
        width: 66.6666666667%
    }

    .col-md-9 {
        width: 75%
    }

    .col-md-10 {
        width: 83.3333333333%
    }

    .col-md-11 {
        width: 91.6666666667%
    }

    .col-md-12 {
        width: 100%
    }

    .col-md-push-0 {
        left: auto
    }

    .col-md-push-1 {
        left: 8.3333333333%
    }

    .col-md-push-2 {
        left: 16.6666666667%
    }

    .col-md-push-3 {
        left: 25%
    }

    .col-md-push-4 {
        left: 33.3333333333%
    }

    .col-md-push-5 {
        left: 41.6666666667%
    }

    .col-md-push-6 {
        left: 50%
    }

    .col-md-push-7 {
        left: 58.3333333333%
    }

    .col-md-push-8 {
        left: 66.6666666667%
    }

    .col-md-push-9 {
        left: 75%
    }

    .col-md-push-10 {
        left: 83.3333333333%
    }

    .col-md-push-11 {
        left: 91.6666666667%
    }

    .col-md-pull-0 {
        right: auto
    }

    .col-md-pull-1 {
        right: 8.3333333333%
    }

    .col-md-pull-2 {
        right: 16.6666666667%
    }

    .col-md-pull-3 {
        right: 25%
    }

    .col-md-pull-4 {
        right: 33.3333333333%
    }

    .col-md-pull-5 {
        right: 41.6666666667%
    }

    .col-md-pull-6 {
        right: 50%
    }

    .col-md-pull-7 {
        right: 58.3333333333%
    }

    .col-md-pull-8 {
        right: 66.6666666667%
    }

    .col-md-pull-9 {
        right: 75%
    }

    .col-md-pull-10 {
        right: 83.3333333333%
    }

    .col-md-pull-11 {
        right: 91.6666666667%
    }

    .col-md-offset-0 {
        margin-left: 0
    }

    .col-md-offset-1 {
        margin-left: 8.3333333333%
    }

    .col-md-offset-2 {
        margin-left: 16.6666666667%
    }

    .col-md-offset-3 {
        margin-left: 25%
    }

    .col-md-offset-4 {
        margin-left: 33.3333333333%
    }

    .col-md-offset-5 {
        margin-left: 41.6666666667%
    }

    .col-md-offset-6 {
        margin-left: 50%
    }

    .col-md-offset-7 {
        margin-left: 58.3333333333%
    }

    .col-md-offset-8 {
        margin-left: 66.6666666667%
    }

    .col-md-offset-9 {
        margin-left: 75%
    }

    .col-md-offset-10 {
        margin-left: 83.3333333333%
    }

    .col-md-offset-11 {
        margin-left: 91.6666666667%
    }
}

@media(min-width: 1200px) {
    .container {
        max-width: 1170px
    }

    .col-lg-1,
    .col-lg-2,
    .col-lg-3,
    .col-lg-4,
    .col-lg-5,
    .col-lg-6,
    .col-lg-7,
    .col-lg-8,
    .col-lg-9,
    .col-lg-10,
    .col-lg-11 {
        float: left
    }

    .col-lg-1 {
        width: 8.3333333333%
    }

    .col-lg-2 {
        width: 16.6666666667%
    }

    .col-lg-3 {
        width: 25%
    }

    .col-lg-4 {
        width: 33.3333333333%
    }

    .col-lg-5 {
        width: 41.6666666667%
    }

    .col-lg-6 {
        width: 50%
    }

    .col-lg-7 {
        width: 58.3333333333%
    }

    .col-lg-8 {
        width: 66.6666666667%
    }

    .col-lg-9 {
        width: 75%
    }

    .col-lg-10 {
        width: 83.3333333333%
    }

    .col-lg-11 {
        width: 91.6666666667%
    }

    .col-lg-12 {
        width: 100%
    }

    .col-lg-push-0 {
        left: auto
    }

    .col-lg-push-1 {
        left: 8.3333333333%
    }

    .col-lg-push-2 {
        left: 16.6666666667%
    }

    .col-lg-push-3 {
        left: 25%
    }

    .col-lg-push-4 {
        left: 33.3333333333%
    }

    .col-lg-push-5 {
        left: 41.6666666667%
    }

    .col-lg-push-6 {
        left: 50%
    }

    .col-lg-push-7 {
        left: 58.3333333333%
    }

    .col-lg-push-8 {
        left: 66.6666666667%
    }

    .col-lg-push-9 {
        left: 75%
    }

    .col-lg-push-10 {
        left: 83.3333333333%
    }

    .col-lg-push-11 {
        left: 91.6666666667%
    }

    .col-lg-pull-0 {
        right: auto
    }

    .col-lg-pull-1 {
        right: 8.3333333333%
    }

    .col-lg-pull-2 {
        right: 16.6666666667%
    }

    .col-lg-pull-3 {
        right: 25%
    }

    .col-lg-pull-4 {
        right: 33.3333333333%
    }

    .col-lg-pull-5 {
        right: 41.6666666667%
    }

    .col-lg-pull-6 {
        right: 50%
    }

    .col-lg-pull-7 {
        right: 58.3333333333%
    }

    .col-lg-pull-8 {
        right: 66.6666666667%
    }

    .col-lg-pull-9 {
        right: 75%
    }

    .col-lg-pull-10 {
        right: 83.3333333333%
    }

    .col-lg-pull-11 {
        right: 91.6666666667%
    }

    .col-lg-offset-0 {
        margin-left: 0
    }

    .col-lg-offset-1 {
        margin-left: 8.3333333333%
    }

    .col-lg-offset-2 {
        margin-left: 16.6666666667%
    }

    .col-lg-offset-3 {
        margin-left: 25%
    }

    .col-lg-offset-4 {
        margin-left: 33.3333333333%
    }

    .col-lg-offset-5 {
        margin-left: 41.6666666667%
    }

    .col-lg-offset-6 {
        margin-left: 50%
    }

    .col-lg-offset-7 {
        margin-left: 58.3333333333%
    }

    .col-lg-offset-8 {
        margin-left: 66.6666666667%
    }

    .col-lg-offset-9 {
        margin-left: 75%
    }

    .col-lg-offset-10 {
        margin-left: 83.3333333333%
    }

    .col-lg-offset-11 {
        margin-left: 91.6666666667%
    }
}

.accordion {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
}

.accordion .a-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 5px
}

.accordion .a-container .a-btn {
    margin: 0;
    position: relative;
    padding: .75rem 0;
    width: 100%;
    color: #1d2d35;
    display: block;
    font-weight: 700;
    font-size: .85rem;
    cursor: pointer;
    border-bottom: 1px solid #ced3dc;
    transition: all .3s ease-in-out
}

.accordion .a-container .cta-btn {
    display: block;
    max-width: 290px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    border: 2px rgba(43, 138, 140, 1) solid;
    color: rgba(43, 138, 140, 1);
    font-weight: bold;
    font-size: 14px;
    padding: 16px 24px;
    width: auto;
    transition: all 0.3s ease-in-out;
}

.accordion .a-container .cta-btn:hover {
    color: white;
    background: rgba(43, 138, 140, 1);
}

.accordion .a-container .a-btn:hover {
    color: rgba(43, 138, 140, 1)
}

.accordion .a-container .a-btn span {
    display: block;
    position: absolute;
    height: 8px;
    width: 8px;
    right: .75rem;
    top: 18px
}

.accordion .a-container .a-btn span:after {
    content: "";
    width: 8px;
    height: 1px;
    background-color: #1d2d35;
    position: absolute;
    top: 10px
}

.accordion .a-container .a-btn span:before {
    content: "";
    width: 8px;
    height: 1px;
    background-color: #1d2d35;
    position: absolute;
    top: 10px;
    transform: rotate(90deg);
    transition: all .3s ease-in-out
}

.accordion .a-container .a-panel {
    width: 100%;
    color: #8391a7;
    transition: all .2s ease-in-out;
    opacity: 0;
    height: auto;
    max-height: 0;
    overflow: hidden;
    padding: 0;
    font-size: .85rem
}

.accordion .a-container.active .a-btn {
    color: rgba(43, 138, 140, 1)
}

.accordion .a-container.active .a-btn span::before {
    transform: rotate(0deg)
}

.accordion .a-container.active .a-panel {
    padding: .75rem 0;
    opacity: 1;
    max-height: 500px
}

.btn {
    display: inline-block;
    color: #000;
    background-color: #8391a7;
    border-color: #8391a7;
    text-align: center;
    font-weight: 400;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid #8391a7;
    padding: .75rem 1.5rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 6px;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    margin-bottom: .25rem;
    font-family: inherit;
    cursor: pointer;
    text-decoration: none;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, .05), 0 1px 2px 0 rgba(0, 0, 0, .08)
}

.btn:focus {
    outline: 2px solid rgba(43, 138, 140, 1)
}

.btn.full-width {
    width: 100%
}

.btn.btn-primary {
    color: #fff;
    background-color: rgba(43, 138, 140, 1);
    border-color: rgba(43, 138, 140, 1)
}

.btn.btn-primary:hover {
    background-color: #f91746;
    border-color: #f91746
}

.btn.btn-secondary {
    color: rgba(43, 138, 140, 1);
    background-color: rgba(0, 0, 0, 0);
    border-color: rgba(43, 138, 140, 1)
}

.btn.btn-secondary:hover {
    background-color: rgba(43, 138, 140, 1);
    border-color: rgba(43, 138, 140, 1);
    color: #fff
}

.card {
    background: #fff;
    padding: 3rem;
    border-radius: .375rem
}

.card h3 {
    font-size: 1.25rem
}

.card .card-price .price {
    font-size: 2.5rem;
    font-weight: 700;
    color: #1d2d35
}

.card .card-price .term {
    font-size: .85rem
}

.card ul {
    list-style: none;
    padding: 0;
    text-align: left
}

.card ul li {
    padding-bottom: .75rem;
    font-size: .85rem
}

.card ul li svg {
    color: #88c047;
    width: 20px;
    position: relative;
    top: 4px;
    margin-right: .75rem
}

@media all and (max-width: 768px) {
    .card {
        margin-top: 1.5rem
    }
}

#navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #475264;
    padding-bottom: 1.5rem;
    padding-top: 1.5rem
}

#navbar ul {
    list-style: none;
    margin: 0;
    padding: 0
}

#navbar ul li {
    display: inline-block;
    padding: .375rem 1.5rem;
    position: relative
}

#navbar ul li.active:before {
    content: "";
    background: rgba(43, 138, 140, 1);
    position: absolute;
    bottom: -1.5rem;
    width: 100%;
    height: 2px;
    left: 0
}

#navbar ul li a {
    color: #fff;
    text-decoration: none;
    font-size: .85rem;
    transition: all .3s ease-in-out
}

#navbar ul li a:hover {
    color: rgba(43, 138, 140, 1)
}

@media all and (max-width: 600px) {
    #navbar {
        flex-direction: column
    }

    #navbar nav {
        text-align: center
    }

    #navbar nav ul {
        flex-direction: column
    }

    #navbar nav ul li.active:before {
        display: none
    }

    #navbar nav ul li.active a {
        color: rgba(43, 138, 140, 1)
    }
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@-webkit-keyframes fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -50px, 0);
        transform: translate3d(0, -10px, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

.color-black {
    color: #1d2d35
}

.mr-0 {
    margin-right: 0
}

.mr-xsm {
    margin-right: .375rem
}

.mr-sm {
    margin-right: .75rem
}

.mr-md {
    margin-right: 1.5rem
}

.mr-lg {
    margin-right: 3rem
}

.mr-xl {
    margin-right: 6rem
}

.mr-xxl {
    margin-right: 12rem
}

.mb-0 {
    margin-bottom: 0
}

.mb-xsm {
    margin-bottom: .375rem
}

.mb-sm {
    margin-bottom: .75rem
}

.mb-md {
    margin-bottom: 1.5rem
}

.mb-lg {
    margin-bottom: 3rem
}

.mb-xl {
    margin-bottom: 6rem
}

.mb-xxl {
    margin-bottom: 12rem
}

.mt-0 {
    margin-top: 0
}

.mt-xsm {
    margin-top: .375rem
}

.mt-sm {
    margin-top: .75rem
}

.mt-md {
    margin-top: 1.5rem
}

.mt-lg {
    margin-top: 3rem
}

.mt-xl {
    margin-top: 6rem
}

.mt-xxl {
    margin-top: 12rem
}

.text-center {
    text-align: center
}

.text-left {
    text-align: left
}

.text-right {
    text-align: right
}

.font-semibold {
    font-weight: 600
}

.d-flex {
    display: flex
}

.items-center {
    align-items: center
}

.justify-center {
    justify-content: center
}