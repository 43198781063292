.waveform {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.waveform>div {
    display: inline-block;
    width: 5px;
    border-radius: 50px;
    height: 30px;
    margin: 0 3px;
    background: var(--message-bg-2);
    transform: scaleY(0.5);
    opacity: 0.25;
}

.waveform.error {
    color: #f7451d;
    min-width: 20em;
    max-width: 30em;
    margin: 0 auto;
    white-space: pre-line;
}