@import '~antd/dist/antd.css';
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

* {
  box-sizing: border-box;
}

#root {
  height: 100vh;
}

html,
body {
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  font-family:'Roboto';
  font-weight: normal;
  overflow: hidden;
}

.modal-backdrop.show {
  opacity: 0.8;
}

/* Custom sroll bar */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  width: 5px;
  background: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  width: 1em;
  background-color: #ddd;
  border-radius: 1rem;
}

button {
  outline: none;
  transition: 0.2s;
  cursor: pointer;
}

button:hover {
  opacity: 0.7;
}

body {
  --app-background: #eaebf5;
  --chat-background: #fff;
  --link-color: #c0c1c5;
  --navigation-bg: #fff;
  --navigation-box-shadow: 0 2px 6px 0 rgba(136, 148, 171, 0.2), 0 24px 20px -24px rgba(71, 82, 107, 0.1);
  --main-color: #4a90e2;
  --message-bg: #f6f6f7;
  --message-bg-2: #2B8A8C;
  --message-text: #2c303a;
  --placeholder-text: #a2a4bc;
  --button-bg: #fff;
}

a {
  text-decoration: none;
}

.nav-link+.nav-link {
  margin-top: 32px;
}

.nav-link:hover svg {
  color: #3d42df;
}

.icon svg {
  width: 24px;
  height: 24px;
  color: var(--link-color);
  transition: 0.2s ease-in;
}

.right-side {
  margin-left: auto;
  flex-basis: 500px;
  border-radius: 16px;
  height: 80%;
  padding: 16px;
  position: absolute;
  right: 0;
  bottom: 32px;
  max-width: 400px;
  width: 100%;
  transform: translateX(100%);
  transition: all 300ms cubic-bezier(0.19, 1, 0.56, 1);
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -ms-transform: translateX(100%);
  -o-transform: translateX(100%);
  z-index: 100;
}

.right-side.show {
  display: block;
  transform: translateX(0);
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
}

.message-wrapper {
  display: flex;
}

.message {
  margin-top: 8px;
  background-color: var(--message-bg);
  padding: 8px 16px;
  border-radius: 8px;
  font-size: 14px;
  line-height: 16px;
  /* max-width: calc(100% - 32px); */
  color: var(--message-text);
}

/* .message-wrapper {
  padding: 8px 0;
} */

.message-wrapper.reverse {
  flex-direction: row-reverse;
}

.message-wrapper.reverse .message {
  background-color: var(--message-bg-2);
  color: #fff;
  margin-left: auto;
  border-radius: 8px;
}

.message-wrapper.reverse .name {
  text-align: right;
}

.video-action-button.endcall {
  color: #ff1932;
  width: auto;
  font-size: 14px;
  padding-left: 42px;
  padding-right: 12px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23ff1932' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-phone-missed'%3E%3Cline x1='23' y1='1' x2='17' y2='7'/%3E%3Cline x1='17' y1='1' x2='23' y2='7'/%3E%3Cpath d='M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z'/%3E%3C/svg%3E");
  background-size: 20px;
  background-position: center left 12px;
}

@media screen and (max-width: 900px) {

  .right-side {
    position: absolute;
    right: 0px;
    background-color: var(--chat-background);
    transform: translateX(100%);
    z-index: 200;
    max-width: 400px;
    width: 100%;
  }

  .right-side.show {
    transform: translateX(0);
  }
}

@media screen and (max-width: 520px) {

  .video-action-button {
    width: 32px;
    height: 32px;
    background-size: 14px;
    margin: 0 4px;
  }

  .video-action-button.endcall {
    background-size: 14px;
    font-size: 12px;
    padding-left: 36px;
  }

  .video-action-button.magnifier svg {
    width: 14px;
  }

  .video-action-button.magnifier span {
    margin: 0 4px;
    font-size: 12px;
  }
}