@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap);
body {
    margin: 0;
    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

*,
*::after,
*::before {
    box-sizing: border-box;
}

#root {
    position: absolute;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 0;
    overflow: hidden;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
}

/* make sure to set some focus styles for accessibility */
:focus {
    outline: 0;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
    -webkit-appearance: none;
    -moz-appearance: none;
}

input[type=search] {
    -webkit-appearance: none;
    -moz-appearance: none;
    box-sizing: content-box;
}

textarea {
    overflow: auto;
    vertical-align: top;
    resize: vertical;
}

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */

audio,
canvas,
video {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    max-width: 100%;
}

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */

audio:not([controls]) {
    display: none;
    height: 0;
}

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */

[hidden] {
    display: none;
}

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */

html {
    font-size: 100%;
    /* 1 */
    -webkit-text-size-adjust: 100%;
    /* 2 */
    -ms-text-size-adjust: 100%;
    /* 2 */
}

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */

a:focus {
    outline: thin dotted;
}

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */

a:active,
a:hover {
    outline: 0;
}

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */

img {
    border: 0;
    /* 1 */
    -ms-interpolation-mode: bicubic;
    /* 2 */
}

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */

figure {
    margin: 0;
}

/**
 * Correct margin displayed oddly in IE 6/7.
 */

form {
    margin: 0;
}

/**
 * Define consistent border, margin, and padding.
 */

fieldset {
    border: 1px solid #c0c0c0;
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */

legend {
    border: 0;
    /* 1 */
    padding: 0;
    white-space: normal;
    /* 2 */
    *margin-left: -7px;
    /* 3 */
}

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */

button,
input,
select,
textarea {
    font-size: 100%;
    /* 1 */
    margin: 0;
    /* 2 */
    vertical-align: baseline;
    /* 3 */
    *vertical-align: middle;
    /* 3 */
}

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */

button,
input {
    line-height: normal;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */

button,
select {
    text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */

button,
html input[type="button"],
/* 1 */
input[type="reset"],
input[type="submit"] {
    -webkit-appearance: button;
    /* 2 */
    cursor: pointer;
    /* 3 */
    *overflow: visible;
    /* 4 */
}

/**
 * Re-set default cursor for disabled elements.
 */

button[disabled],
html input[disabled] {
    cursor: default;
}

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */

input[type="checkbox"],
input[type="radio"] {
    box-sizing: border-box;
    /* 1 */
    padding: 0;
    /* 2 */
    *height: 13px;
    /* 3 */
    *width: 13px;
    /* 3 */
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */

input[type="search"] {
    -webkit-appearance: textfield;
    /* 1 */
    /* 2 */
    box-sizing: content-box;
}

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

/**
 * Remove inner padding and border in Firefox 3+.
 */

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */

textarea {
    overflow: auto;
    /* 1 */
    vertical-align: top;
    /* 2 */
}

/**
 * Remove most spacing between table cells.
 */

table {
    border-collapse: collapse;
    border-spacing: 0;
}

html,
button,
input,
select,
textarea {
    color: #222;
}

::selection {
    background: #b3d4fc;
    text-shadow: none;
}

img {
    vertical-align: middle;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

textarea {
    resize: vertical;
}

.chromeframe {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}
* {
  box-sizing: border-box;
}

#root {
  height: 100vh;
}

html,
body {
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  font-family:'Roboto';
  font-weight: normal;
  overflow: hidden;
}

.modal-backdrop.show {
  opacity: 0.8;
}

/* Custom sroll bar */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  width: 5px;
  background: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  width: 1em;
  background-color: #ddd;
  border-radius: 1rem;
}

button {
  outline: none;
  transition: 0.2s;
  cursor: pointer;
}

button:hover {
  opacity: 0.7;
}

body {
  --app-background: #eaebf5;
  --chat-background: #fff;
  --link-color: #c0c1c5;
  --navigation-bg: #fff;
  --navigation-box-shadow: 0 2px 6px 0 rgba(136, 148, 171, 0.2), 0 24px 20px -24px rgba(71, 82, 107, 0.1);
  --main-color: #4a90e2;
  --message-bg: #f6f6f7;
  --message-bg-2: #2B8A8C;
  --message-text: #2c303a;
  --placeholder-text: #a2a4bc;
  --button-bg: #fff;
}

a {
  text-decoration: none;
}

.nav-link+.nav-link {
  margin-top: 32px;
}

.nav-link:hover svg {
  color: #3d42df;
}

.icon svg {
  width: 24px;
  height: 24px;
  color: var(--link-color);
  transition: 0.2s ease-in;
}

.right-side {
  margin-left: auto;
  flex-basis: 500px;
  border-radius: 16px;
  height: 80%;
  padding: 16px;
  position: absolute;
  right: 0;
  bottom: 32px;
  max-width: 400px;
  width: 100%;
  transform: translateX(100%);
  transition: all 300ms cubic-bezier(0.19, 1, 0.56, 1);
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -ms-transform: translateX(100%);
  -o-transform: translateX(100%);
  z-index: 100;
}

.right-side.show {
  display: block;
  transform: translateX(0);
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
}

.message-wrapper {
  display: flex;
}

.message {
  margin-top: 8px;
  background-color: var(--message-bg);
  padding: 8px 16px;
  border-radius: 8px;
  font-size: 14px;
  line-height: 16px;
  /* max-width: calc(100% - 32px); */
  color: var(--message-text);
}

/* .message-wrapper {
  padding: 8px 0;
} */

.message-wrapper.reverse {
  flex-direction: row-reverse;
}

.message-wrapper.reverse .message {
  background-color: var(--message-bg-2);
  color: #fff;
  margin-left: auto;
  border-radius: 8px;
}

.message-wrapper.reverse .name {
  text-align: right;
}

.video-action-button.endcall {
  color: #ff1932;
  width: auto;
  font-size: 14px;
  padding-left: 42px;
  padding-right: 12px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23ff1932' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-phone-missed'%3E%3Cline x1='23' y1='1' x2='17' y2='7'/%3E%3Cline x1='17' y1='1' x2='23' y2='7'/%3E%3Cpath d='M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z'/%3E%3C/svg%3E");
  background-size: 20px;
  background-position: center left 12px;
}

@media screen and (max-width: 900px) {

  .right-side {
    position: absolute;
    right: 0px;
    background-color: var(--chat-background);
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    z-index: 200;
    max-width: 400px;
    width: 100%;
  }

  .right-side.show {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@media screen and (max-width: 520px) {

  .video-action-button {
    width: 32px;
    height: 32px;
    background-size: 14px;
    margin: 0 4px;
  }

  .video-action-button.endcall {
    background-size: 14px;
    font-size: 12px;
    padding-left: 36px;
  }

  .video-action-button.magnifier svg {
    width: 14px;
  }

  .video-action-button.magnifier span {
    margin: 0 4px;
    font-size: 12px;
  }
}
.waveform {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.waveform>div {
    display: inline-block;
    width: 5px;
    border-radius: 50px;
    height: 30px;
    margin: 0 3px;
    background: var(--message-bg-2);
    -webkit-transform: scaleY(0.5);
            transform: scaleY(0.5);
    opacity: 0.25;
}

.waveform.error {
    color: #f7451d;
    min-width: 20em;
    max-width: 30em;
    margin: 0 auto;
    white-space: pre-line;
}
.heading {
  font-size: 18px;
}

.para {
  font-weight: 400;
}

.feedback-level {
  display: flex;
  justify-content: center;
  align-items: center;
}
.level {
  background: white;
  padding: 8px;
  border-radius: 7px;
  cursor: pointer;
}
.level:hover i {
  color: #2B8A8C;
}
.level i {
  font-size: 40px;
  font-weight: 400;
}

.feedback-msg textarea {
  margin: 8px 0;
  border: solid 2px #e0e9fe;
  padding: 8px;
  border-radius: 7px;
  outline: none;
  font-size: 14px;
  color: #4b537c;
  font-weight: 500;
  letter-spacing: 1px;
  width: 100%;
  height: 80px;
  resize: none;
}

.agreement {
    margin: 8px 0;
}

.agreement .checkbox {
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  align-items: center;
  margin-bottom: 5px;
}
.agreement input {
  accent-color: #11304F;
}
.agreement a {
  color: #11304F;
  font-size: 12px;
  font-weight: 600;
}
.agreement label {
  font-size: 12px;
}

.buttons {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  justify-content: end;
}
.buttons a {
  background: white;
  color: #4b537c;
  width: 80px;
  padding: 10px;
  text-align: center;
  border-radius: 4px;
  font-weight: bold;
}
.buttons a:hover {
  color: #2B8A8C;
}

.buttons a:nth-child(2):hover {
    background: #2B8A8C;
    color: white;
  }

.buttons a:nth-child(2) {
  background: #11304F;
  color: #f8faff;
}

html {
    font-size: 1rem;
    line-height: 1.65;
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
    -webkit-text-size-adjust: 100%;
    image-rendering: crisp-edges;
    scroll-behavior: smooth
}

main {
    display: block
}

h1 {
    font-size: 2em
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible
}

a {
    background-color: rgba(0, 0, 0, 0)
}

abbr[title] {
    border-bottom: none;
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
            text-decoration: underline dotted
}

b,
strong {
    font-weight: bolder
}

small {
    font-size: 80%
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline
}

sub {
    bottom: -0.25em
}

sup {
    top: -0.5em
}

img {
    border-style: none
}

button,
input,
optgroup,
select,
textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0
}

button,
input {
    overflow: visible
}

button,
select {
    text-transform: none
}

button,
[type=button],
[type=reset],
[type=submit] {
    -webkit-appearance: button
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
    border-style: none;
    padding: 0
}

button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
    outline: 1px dotted ButtonText
}

fieldset {
    padding: .35em .75em .625em
}

legend {
    box-sizing: border-box;
    color: inherit;
    display: table;
    max-width: 100%;
    padding: 0;
    white-space: normal
}

progress {
    vertical-align: baseline
}

textarea {
    overflow: auto
}

[type=checkbox],
[type=radio] {
    box-sizing: border-box;
    padding: 0
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
    height: auto
}

[type=search] {
    -webkit-appearance: textfield;
    outline-offset: -2px
}

[type=search]::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit
}

details {
    display: block
}

summary {
    display: list-item
}

template {
    display: none
}

[hidden] {
    display: none
}

*,
*::before,
*::after {
    box-sizing: inherit
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: 1.5rem;
    line-height: 1.25;
    font-weight: 600;
    color: #1d2d35
}

h1 {
    font-size: 2rem
}

h2 {
    font-size: 1.75rem
}

h3 {
    font-size: 1.5rem
}

h4 {
    font-size: 1.25rem
}

h5 {
    font-size: 1.1rem
}

h6 {
    font-size: 1rem
}

@media(min-width: 768px) {
    h1 {
        font-size: 3rem
    }

    h1.jumbo {
        font-size: 3.5rem
    }

    h2 {
        font-size: 2.5rem;
        margin-top: .75rem
    }

    h3 {
        font-size: 2rem
    }

    h4 {
        font-size: 1.5rem
    }

    h5 {
        font-size: 1.25rem
    }

    h6 {
        font-size: 1rem
    }
}

.kicker {
    text-transform: uppercase;
    color: rgba(43, 138, 140, 1);
    font-weight: 400;
    font-size: .85rem !important;
    letter-spacing: 1px;
    margin-bottom: .75rem;
    font-family: 'Roboto'
}

header {
    padding-bottom: 12rem
}

header #navbar .logo {
    max-width: 100px
}

#hero {
    padding: 32px 0 32px 0;
    background-image: radial-gradient(54.35% 54.35% at 13.82% 7%, #162051 0%, #161B32 100%)
}

#hero h1 {
    color: #fff;
    -webkit-animation: fadeInUp 600ms .2s;
            animation: fadeInUp 600ms .2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both
}

#hero p {
    font-size: 1rem;
    -webkit-animation: fadeInUp 600ms .2s;
            animation: fadeInUp 600ms .2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    line-height: 1.5;
}

#hero .btn {
    -webkit-animation: fadeInUp 600ms .2s;
            animation: fadeInUp 600ms .2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both
}

#hero .btn.btn-secondary {
    color: #fff;
    border-color: #fff
}

#hero .btn.btn-secondary:hover {
    background-color: #fff;
    border-color: #fff;
    color: #1d2d35
}

@media all and (max-width: 768px) {
    header {
        padding-bottom: 6rem
    }

    #hero {
        padding: 0 0 6rem 0
    }

    #hero .hero-img {
        max-width: 65%;
        margin-top: 1.5rem
    }
}

#intro {
    padding: 2.5rem 0
}

#intro p {
    line-height: 1.5;
}

#intro .img-right {
    width: 100%;
    display: block;
    max-width: 500px;
    margin-left: auto;
    border-radius: 8px;
}

@media all and (max-width: 768px) {
    #intro {
        padding: 6rem 0
    }
}

#cta {
    background: #f9fafb;
    padding: 3rem 0
}

footer {
    padding: 6rem 0;
    background: radial-gradient(54.35% 54.35% at 13.82% 7%, #162051 0%, #161B32 100%)
}

footer h3 {
    font-size: .85rem;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 2px
}

footer ul {
    list-style: none;
    padding-left: 0
}

footer ul li {
    margin-bottom: 1.5rem
}

footer ul li a {
    color: #8391a7;
    text-decoration: none;
    font-size: .85rem;
    transition: all .3s ease-in-out
}

footer ul li a:hover {
    color: rgba(43, 138, 140, 1)
}

footer ul.social li {
    display: inline;
    margin-right: .75rem
}

footer ul.social li a {
    color: #fff
}

footer ul.social li a svg {
    max-width: 25px
}

footer ul.social li a:hover {
    color: rgba(43, 138, 140, 1)
}

footer .logo {
    max-width: 100px;
    margin-bottom: .75rem
}

.container {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px
}

.row {
    margin-left: -15px;
    margin-right: -15px
}

.row:before,
.row:after {
    content: " ";
    display: table
}

.row:after {
    clear: both
}

.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12 {
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px
}

.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11 {
    float: left
}

.col-xs-1 {
    width: 8.3333333333%
}

.col-xs-2 {
    width: 16.6666666667%
}

.col-xs-3 {
    width: 25%
}

.col-xs-4 {
    width: 33.3333333333%
}

.col-xs-5 {
    width: 41.6666666667%
}

.col-xs-6 {
    width: 50%
}

.col-xs-7 {
    width: 58.3333333333%
}

.col-xs-8 {
    width: 66.6666666667%
}

.col-xs-9 {
    width: 75%
}

.col-xs-10 {
    width: 83.3333333333%
}

.col-xs-11 {
    width: 91.6666666667%
}

.col-xs-12 {
    width: 100%
}

@media(min-width: 768px) {
    .container {
        max-width: 750px
    }

    .col-sm-1,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9,
    .col-sm-10,
    .col-sm-11 {
        float: left
    }

    .col-sm-1 {
        width: 8.3333333333%
    }

    .col-sm-2 {
        width: 16.6666666667%
    }

    .col-sm-3 {
        width: 25%
    }

    .col-sm-4 {
        width: 33.3333333333%
    }

    .col-sm-5 {
        width: 41.6666666667%
    }

    .col-sm-6 {
        width: 50%
    }

    .col-sm-7 {
        width: 58.3333333333%
    }

    .col-sm-8 {
        width: 66.6666666667%
    }

    .col-sm-9 {
        width: 75%
    }

    .col-sm-10 {
        width: 83.3333333333%
    }

    .col-sm-11 {
        width: 91.6666666667%
    }

    .col-sm-12 {
        width: 100%
    }

    .col-sm-push-1 {
        left: 8.3333333333%
    }

    .col-sm-push-2 {
        left: 16.6666666667%
    }

    .col-sm-push-3 {
        left: 25%
    }

    .col-sm-push-4 {
        left: 33.3333333333%
    }

    .col-sm-push-5 {
        left: 41.6666666667%
    }

    .col-sm-push-6 {
        left: 50%
    }

    .col-sm-push-7 {
        left: 58.3333333333%
    }

    .col-sm-push-8 {
        left: 66.6666666667%
    }

    .col-sm-push-9 {
        left: 75%
    }

    .col-sm-push-10 {
        left: 83.3333333333%
    }

    .col-sm-push-11 {
        left: 91.6666666667%
    }

    .col-sm-pull-1 {
        right: 8.3333333333%
    }

    .col-sm-pull-2 {
        right: 16.6666666667%
    }

    .col-sm-pull-3 {
        right: 25%
    }

    .col-sm-pull-4 {
        right: 33.3333333333%
    }

    .col-sm-pull-5 {
        right: 41.6666666667%
    }

    .col-sm-pull-6 {
        right: 50%
    }

    .col-sm-pull-7 {
        right: 58.3333333333%
    }

    .col-sm-pull-8 {
        right: 66.6666666667%
    }

    .col-sm-pull-9 {
        right: 75%
    }

    .col-sm-pull-10 {
        right: 83.3333333333%
    }

    .col-sm-pull-11 {
        right: 91.6666666667%
    }

    .col-sm-offset-1 {
        margin-left: 8.3333333333%
    }

    .col-sm-offset-2 {
        margin-left: 16.6666666667%
    }

    .col-sm-offset-3 {
        margin-left: 25%
    }

    .col-sm-offset-4 {
        margin-left: 33.3333333333%
    }

    .col-sm-offset-5 {
        margin-left: 41.6666666667%
    }

    .col-sm-offset-6 {
        margin-left: 50%
    }

    .col-sm-offset-7 {
        margin-left: 58.3333333333%
    }

    .col-sm-offset-8 {
        margin-left: 66.6666666667%
    }

    .col-sm-offset-9 {
        margin-left: 75%
    }

    .col-sm-offset-10 {
        margin-left: 83.3333333333%
    }

    .col-sm-offset-11 {
        margin-left: 91.6666666667%
    }
}

@media(min-width: 992px) {
    .container {
        max-width: 970px
    }

    .col-md-1,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-md-10,
    .col-md-11 {
        float: left
    }

    .col-md-1 {
        width: 8.3333333333%
    }

    .col-md-2 {
        width: 16.6666666667%
    }

    .col-md-3 {
        width: 25%
    }

    .col-md-4 {
        width: 33.3333333333%
    }

    .col-md-5 {
        width: 41.6666666667%
    }

    .col-md-6 {
        width: 50%
    }

    .col-md-7 {
        width: 58.3333333333%
    }

    .col-md-8 {
        width: 66.6666666667%
    }

    .col-md-9 {
        width: 75%
    }

    .col-md-10 {
        width: 83.3333333333%
    }

    .col-md-11 {
        width: 91.6666666667%
    }

    .col-md-12 {
        width: 100%
    }

    .col-md-push-0 {
        left: auto
    }

    .col-md-push-1 {
        left: 8.3333333333%
    }

    .col-md-push-2 {
        left: 16.6666666667%
    }

    .col-md-push-3 {
        left: 25%
    }

    .col-md-push-4 {
        left: 33.3333333333%
    }

    .col-md-push-5 {
        left: 41.6666666667%
    }

    .col-md-push-6 {
        left: 50%
    }

    .col-md-push-7 {
        left: 58.3333333333%
    }

    .col-md-push-8 {
        left: 66.6666666667%
    }

    .col-md-push-9 {
        left: 75%
    }

    .col-md-push-10 {
        left: 83.3333333333%
    }

    .col-md-push-11 {
        left: 91.6666666667%
    }

    .col-md-pull-0 {
        right: auto
    }

    .col-md-pull-1 {
        right: 8.3333333333%
    }

    .col-md-pull-2 {
        right: 16.6666666667%
    }

    .col-md-pull-3 {
        right: 25%
    }

    .col-md-pull-4 {
        right: 33.3333333333%
    }

    .col-md-pull-5 {
        right: 41.6666666667%
    }

    .col-md-pull-6 {
        right: 50%
    }

    .col-md-pull-7 {
        right: 58.3333333333%
    }

    .col-md-pull-8 {
        right: 66.6666666667%
    }

    .col-md-pull-9 {
        right: 75%
    }

    .col-md-pull-10 {
        right: 83.3333333333%
    }

    .col-md-pull-11 {
        right: 91.6666666667%
    }

    .col-md-offset-0 {
        margin-left: 0
    }

    .col-md-offset-1 {
        margin-left: 8.3333333333%
    }

    .col-md-offset-2 {
        margin-left: 16.6666666667%
    }

    .col-md-offset-3 {
        margin-left: 25%
    }

    .col-md-offset-4 {
        margin-left: 33.3333333333%
    }

    .col-md-offset-5 {
        margin-left: 41.6666666667%
    }

    .col-md-offset-6 {
        margin-left: 50%
    }

    .col-md-offset-7 {
        margin-left: 58.3333333333%
    }

    .col-md-offset-8 {
        margin-left: 66.6666666667%
    }

    .col-md-offset-9 {
        margin-left: 75%
    }

    .col-md-offset-10 {
        margin-left: 83.3333333333%
    }

    .col-md-offset-11 {
        margin-left: 91.6666666667%
    }
}

@media(min-width: 1200px) {
    .container {
        max-width: 1170px
    }

    .col-lg-1,
    .col-lg-2,
    .col-lg-3,
    .col-lg-4,
    .col-lg-5,
    .col-lg-6,
    .col-lg-7,
    .col-lg-8,
    .col-lg-9,
    .col-lg-10,
    .col-lg-11 {
        float: left
    }

    .col-lg-1 {
        width: 8.3333333333%
    }

    .col-lg-2 {
        width: 16.6666666667%
    }

    .col-lg-3 {
        width: 25%
    }

    .col-lg-4 {
        width: 33.3333333333%
    }

    .col-lg-5 {
        width: 41.6666666667%
    }

    .col-lg-6 {
        width: 50%
    }

    .col-lg-7 {
        width: 58.3333333333%
    }

    .col-lg-8 {
        width: 66.6666666667%
    }

    .col-lg-9 {
        width: 75%
    }

    .col-lg-10 {
        width: 83.3333333333%
    }

    .col-lg-11 {
        width: 91.6666666667%
    }

    .col-lg-12 {
        width: 100%
    }

    .col-lg-push-0 {
        left: auto
    }

    .col-lg-push-1 {
        left: 8.3333333333%
    }

    .col-lg-push-2 {
        left: 16.6666666667%
    }

    .col-lg-push-3 {
        left: 25%
    }

    .col-lg-push-4 {
        left: 33.3333333333%
    }

    .col-lg-push-5 {
        left: 41.6666666667%
    }

    .col-lg-push-6 {
        left: 50%
    }

    .col-lg-push-7 {
        left: 58.3333333333%
    }

    .col-lg-push-8 {
        left: 66.6666666667%
    }

    .col-lg-push-9 {
        left: 75%
    }

    .col-lg-push-10 {
        left: 83.3333333333%
    }

    .col-lg-push-11 {
        left: 91.6666666667%
    }

    .col-lg-pull-0 {
        right: auto
    }

    .col-lg-pull-1 {
        right: 8.3333333333%
    }

    .col-lg-pull-2 {
        right: 16.6666666667%
    }

    .col-lg-pull-3 {
        right: 25%
    }

    .col-lg-pull-4 {
        right: 33.3333333333%
    }

    .col-lg-pull-5 {
        right: 41.6666666667%
    }

    .col-lg-pull-6 {
        right: 50%
    }

    .col-lg-pull-7 {
        right: 58.3333333333%
    }

    .col-lg-pull-8 {
        right: 66.6666666667%
    }

    .col-lg-pull-9 {
        right: 75%
    }

    .col-lg-pull-10 {
        right: 83.3333333333%
    }

    .col-lg-pull-11 {
        right: 91.6666666667%
    }

    .col-lg-offset-0 {
        margin-left: 0
    }

    .col-lg-offset-1 {
        margin-left: 8.3333333333%
    }

    .col-lg-offset-2 {
        margin-left: 16.6666666667%
    }

    .col-lg-offset-3 {
        margin-left: 25%
    }

    .col-lg-offset-4 {
        margin-left: 33.3333333333%
    }

    .col-lg-offset-5 {
        margin-left: 41.6666666667%
    }

    .col-lg-offset-6 {
        margin-left: 50%
    }

    .col-lg-offset-7 {
        margin-left: 58.3333333333%
    }

    .col-lg-offset-8 {
        margin-left: 66.6666666667%
    }

    .col-lg-offset-9 {
        margin-left: 75%
    }

    .col-lg-offset-10 {
        margin-left: 83.3333333333%
    }

    .col-lg-offset-11 {
        margin-left: 91.6666666667%
    }
}

.accordion {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
}

.accordion .a-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 5px
}

.accordion .a-container .a-btn {
    margin: 0;
    position: relative;
    padding: .75rem 0;
    width: 100%;
    color: #1d2d35;
    display: block;
    font-weight: 700;
    font-size: .85rem;
    cursor: pointer;
    border-bottom: 1px solid #ced3dc;
    transition: all .3s ease-in-out
}

.accordion .a-container .cta-btn {
    display: block;
    max-width: 290px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    border: 2px rgba(43, 138, 140, 1) solid;
    color: rgba(43, 138, 140, 1);
    font-weight: bold;
    font-size: 14px;
    padding: 16px 24px;
    width: auto;
    transition: all 0.3s ease-in-out;
}

.accordion .a-container .cta-btn:hover {
    color: white;
    background: rgba(43, 138, 140, 1);
}

.accordion .a-container .a-btn:hover {
    color: rgba(43, 138, 140, 1)
}

.accordion .a-container .a-btn span {
    display: block;
    position: absolute;
    height: 8px;
    width: 8px;
    right: .75rem;
    top: 18px
}

.accordion .a-container .a-btn span:after {
    content: "";
    width: 8px;
    height: 1px;
    background-color: #1d2d35;
    position: absolute;
    top: 10px
}

.accordion .a-container .a-btn span:before {
    content: "";
    width: 8px;
    height: 1px;
    background-color: #1d2d35;
    position: absolute;
    top: 10px;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    transition: all .3s ease-in-out
}

.accordion .a-container .a-panel {
    width: 100%;
    color: #8391a7;
    transition: all .2s ease-in-out;
    opacity: 0;
    height: auto;
    max-height: 0;
    overflow: hidden;
    padding: 0;
    font-size: .85rem
}

.accordion .a-container.active .a-btn {
    color: rgba(43, 138, 140, 1)
}

.accordion .a-container.active .a-btn span::before {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg)
}

.accordion .a-container.active .a-panel {
    padding: .75rem 0;
    opacity: 1;
    max-height: 500px
}

.btn {
    display: inline-block;
    color: #000;
    background-color: #8391a7;
    border-color: #8391a7;
    text-align: center;
    font-weight: 400;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    border: 1px solid #8391a7;
    padding: .75rem 1.5rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 6px;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    margin-bottom: .25rem;
    font-family: inherit;
    cursor: pointer;
    text-decoration: none;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, .05), 0 1px 2px 0 rgba(0, 0, 0, .08)
}

.btn:focus {
    outline: 2px solid rgba(43, 138, 140, 1)
}

.btn.full-width {
    width: 100%
}

.btn.btn-primary {
    color: #fff;
    background-color: rgba(43, 138, 140, 1);
    border-color: rgba(43, 138, 140, 1)
}

.btn.btn-primary:hover {
    background-color: #f91746;
    border-color: #f91746
}

.btn.btn-secondary {
    color: rgba(43, 138, 140, 1);
    background-color: rgba(0, 0, 0, 0);
    border-color: rgba(43, 138, 140, 1)
}

.btn.btn-secondary:hover {
    background-color: rgba(43, 138, 140, 1);
    border-color: rgba(43, 138, 140, 1);
    color: #fff
}

.card {
    background: #fff;
    padding: 3rem;
    border-radius: .375rem
}

.card h3 {
    font-size: 1.25rem
}

.card .card-price .price {
    font-size: 2.5rem;
    font-weight: 700;
    color: #1d2d35
}

.card .card-price .term {
    font-size: .85rem
}

.card ul {
    list-style: none;
    padding: 0;
    text-align: left
}

.card ul li {
    padding-bottom: .75rem;
    font-size: .85rem
}

.card ul li svg {
    color: #88c047;
    width: 20px;
    position: relative;
    top: 4px;
    margin-right: .75rem
}

@media all and (max-width: 768px) {
    .card {
        margin-top: 1.5rem
    }
}

#navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #475264;
    padding-bottom: 1.5rem;
    padding-top: 1.5rem
}

#navbar ul {
    list-style: none;
    margin: 0;
    padding: 0
}

#navbar ul li {
    display: inline-block;
    padding: .375rem 1.5rem;
    position: relative
}

#navbar ul li.active:before {
    content: "";
    background: rgba(43, 138, 140, 1);
    position: absolute;
    bottom: -1.5rem;
    width: 100%;
    height: 2px;
    left: 0
}

#navbar ul li a {
    color: #fff;
    text-decoration: none;
    font-size: .85rem;
    transition: all .3s ease-in-out
}

#navbar ul li a:hover {
    color: rgba(43, 138, 140, 1)
}

@media all and (max-width: 600px) {
    #navbar {
        flex-direction: column
    }

    #navbar nav {
        text-align: center
    }

    #navbar nav ul {
        flex-direction: column
    }

    #navbar nav ul li.active:before {
        display: none
    }

    #navbar nav ul li.active a {
        color: rgba(43, 138, 140, 1)
    }
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@-webkit-keyframes fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -50px, 0);
        transform: translate3d(0, -10px, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

.color-black {
    color: #1d2d35
}

.mr-0 {
    margin-right: 0
}

.mr-xsm {
    margin-right: .375rem
}

.mr-sm {
    margin-right: .75rem
}

.mr-md {
    margin-right: 1.5rem
}

.mr-lg {
    margin-right: 3rem
}

.mr-xl {
    margin-right: 6rem
}

.mr-xxl {
    margin-right: 12rem
}

.mb-0 {
    margin-bottom: 0
}

.mb-xsm {
    margin-bottom: .375rem
}

.mb-sm {
    margin-bottom: .75rem
}

.mb-md {
    margin-bottom: 1.5rem
}

.mb-lg {
    margin-bottom: 3rem
}

.mb-xl {
    margin-bottom: 6rem
}

.mb-xxl {
    margin-bottom: 12rem
}

.mt-0 {
    margin-top: 0
}

.mt-xsm {
    margin-top: .375rem
}

.mt-sm {
    margin-top: .75rem
}

.mt-md {
    margin-top: 1.5rem
}

.mt-lg {
    margin-top: 3rem
}

.mt-xl {
    margin-top: 6rem
}

.mt-xxl {
    margin-top: 12rem
}

.text-center {
    text-align: center
}

.text-left {
    text-align: left
}

.text-right {
    text-align: right
}

.font-semibold {
    font-weight: 600
}

.d-flex {
    display: flex
}

.items-center {
    align-items: center
}

.justify-center {
    justify-content: center
}
